<template>
  <div>
    <v-toolbar flat>
            <v-toolbar-title>{{ $t('title.roleLevel') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-text-field
          v-model="params.search"
          append-icon="mdi-magnify"
          :label="$t('input.search')"
          single-line
          hide-details
          clearable
          @input="fetchGetAllRoleLevel(params)"
          class="mr-5"
      /> -->

      <!-- <v-btn color="error"
             @click="dialogDelete = !dialogDelete"
             :disabled="dataDelete.length < 1" v-if="checkUserPermission('DELETE')">
        {{ $t('button.delete_all') }}
      </v-btn> -->

      <!-- <v-btn class="ml-5" color="primary" @click="onCreateDialog" :disabled="true" v-if="checkUserPermission('CREATE')">{{ $t('button.create') }}</v-btn> -->

      <dialog-delete
          :dialog-delete="dialogDelete"
          :darkmode="darkmode"
          v-on:closeDelete="closeDelete"
          v-on:deleteItemConfirm="deleteItemConfirm"
      /> 
    </v-toolbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DialogDelete from "../../../components/shared/notify/dialogDelete.vue";

export default {
  components: {DialogDelete},
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission'],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },

    async deleteItemConfirm() {
      await this.deleteData({
        vm: this,
        data: {deleteItems: this.dataDelete}
      });
      this.fetchGetAllRoleLevel(this.params)
      this.dialogDelete = false;
    },

    async onCreateDialog() {
      this.fetchPopUp(true)
    },
    ...mapActions("$_roleLevel", ["fetchGetAllRoleLevel", "deleteData", "fetchPopUp"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
